<template>
  <div class="product-brands">
    <h1 class="brands-title">{{ $t("brand.brands") }}</h1>

    <!--s: Brands left-->
    <div class="brands-left">
      <div class="brands-left-ul">
        <ul>
          <li>
            <a
              href="javascript:"
              @click="onQueryBrands"
              :class="brandsTabStatus ? 'active' : ''"
              >{{ $t("brand.brands") }}</a
            >
          </li>
          <li>
            <a
              href="javascript:"
              @click="onMyBrands"
              :class="!brandsTabStatus ? 'active' : ''"
              >{{ $t("brand.myBrands") }}</a
            >
          </li>
        </ul>
      </div>
      <!-- s: Brands search -->
      <div class="search-wrapper" ref="searchWrapper">
        <input
          class="search-input"
          type="text"
          :placeholder="$t('header.search')"
          @input="changeSearch"
          v-model="searchContent"
          ref="searchInput"
        />
        <a
          href="javascript:"
          class="clear-icon-wrapper"
          @click="searchContent = ''; onBrandCategory().then()"
        >
          <div class="clear-icon"></div>
        </a>
      </div>
      <!-- e: Brands search -->
    </div>
    <!--e: Brands left-->

    <!--s: Brands content-->
    <div class="brands-content">
      <div class="brands-box">
        <div class="brands-title-box">
          <h1 class="title">
            {{ brandsTabStatus ? $t("brand.brands") : $t("brand.myBrands") }}
          </h1>

          <div class="brands-label">
            <a
              href="javascript:"
              :key="key"
              v-for="(title, key) in titleList"
              @click="onPoint(title)"
              >{{ title }}</a
            >
          </div>
        </div>
        <div class="brands-list">
          <div class="loading" v-if="loadingVisible"></div>
          <div class="box" :key="key" v-for="(brands, key) in displayBrands">
            <h1 class="list-title" v-if="!loadingVisible" :id="brands.letter">
              {{ brands.letter }}
            </h1>
            <ul v-if="!loadingVisible">
              <li
                :key="index"
                v-for="(list, index) in brands.list"
                :style="
                  (index + 1) % 3 === 0 && index != 0 ? 'margin-right:0px' : ''
                "
              >
                <a
                  href="javascript:"
                  class="brand-title-name"
                  @click="onBrandInfo(list, index)"
                  >{{ list.name }}</a
                >
                <div
                  class="brands-info"
                  v-if="brands.list[index].visible"
                  :style="'top:' + offsetTop + 'px'"
                >
                  <div class="brands-info-box">
                    <h1 class="brands-name">{{ list.name }}</h1>
                    <h2 class="brands-subtitle">{{ $t("list.brandTitle") }}</h2>
                    <div class="list">
                      <div class="box">
                        <a
                          href="javascript:"
                          class="label"
                          :class="item.check === 1 ? 'selected' : ''"
                          @click="onBrandCheck(item.cid1, list.id)"
                          :key="i"
                          v-for="(item, i) in brandCategoryConf"
                          :style="
                            menuType === 4 && (i === 5 || i === 6) && isPhone()
                              ? 'width:2.95rem'
                              : menuType === 3 && i === 6
                              ? 'margin-left:0.91rem'
                              : (i === 4 || i === 11) && menuType !== 3
                              ? isPhone()
                                ? 'width:4.85rem'
                                : 'width:149px'
                              : ''
                          "
                          >{{ item.cid1Name }}</a
                        >
                        <a
                          href="javascript:"
                          class="like-box"
                          v-if="brandCategoryConf.length !== 0"
                          :class="
                            brandConfSelect.length === brandCategoryConf.length
                              ? 'like-red'
                              : 'like-grey'
                          "
                          @click="onBrandAll(list.id)"
                        ></a>
                      </div>
                    </div>
                    <div class="brand-view-all">
                      <a href="javascript:" @click="onBrandList(list)"
                        >{{ $t("brand.viewAll") }} <i class="icon"></i
                      ></a>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="brands-no-data"
            v-if="
              !brandsTabStatus && displayBrands.length === 0 && !loadingVisible
            "
          >
            <div class="like-tips-box">
              <span class="tips-image"></span>
              <div class="tips-content">
                <div class="box-tips">
                  <h2 class="tips-title">{{ $t("brand.tipsTitle") }}</h2>
                  <p class="tips-text">{{ $t("brand.tipsText") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="no-data-text"
            v-if="brandsTabStatus && !brandsList.length && !loadingVisible"
          >
            {{ $t("list.noData") }}
          </div>
          <div
            class="no-search-wrapper"
            v-if="searchContent && !displayBrands.length && !loadingVisible"
          >
            <div class="sorry">
              {{ $t("carousel.searchTipsTitle") }}
            </div>
            <div
              class="no-text"
              v-html="$t('brand.searchTipsText', { search: searchContent })"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <!--e: Brands content-->
  </div>
</template>
<script>
import Brands from "./brands";
export default Brands;
</script>
<style lang="less">
@import "brands";
</style>
