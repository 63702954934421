import {
  getBrandCategoryService,
  getMyBrandCategoryService
} from "@/service/goods-brand-service";
import { mapMutations, mapState } from "vuex";
import $event, { EVENT_NAME } from "@/utils/event-emitter";
import Cookies from "js-cookie";
import {
  getBrandCategoryConfService,
  getBrandCreateAllService
} from "@/service/spu-group-service";
import { callBackTop, isPhone } from "@/utils/tools";
/**
 * 品牌列表页
 */

const Brands = {
  name: "Brands",
  computed: {
    ...mapState({
      menuId: (state) => state.product.menuId, //获取状态管理菜单分类
      menuType: (state) => state.product.menuType, //获取状态管理菜单分类
      letter: (state) => state.product.letter //获取状态管理letter
    })
  },
  data() {
    return {
      loadingVisible: false,
      brandsTabStatus: true,
      titleList: [
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "G",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
        "#"
      ],
      selectTitle: "A", //选中的title
      selectBrands: [], //选中的品牌列表
      brandsList: [], //品牌列表数据集
      brandCategoryConf: [], //品牌信息标签数据集
      brandConfSelect: [], //品牌信息标签选中状态
      offsetTop: 0,
      isPhone: isPhone,
      isSearch: false,// 是否展示搜索框
      displayBrands: [], // 品牌搜索结果
      searchContent: ''
    };
  },
  created() {
    /**
     * 查询品牌列表
     */
    $event.$on(EVENT_NAME.PRODUCT_BRANDS_QUERY, () => {
      if (this.brandsTabStatus) {
        this.onBrandCategory().then();
      }
    });
    this.onBrandCategory().then();
  },
  mounted() {
    document.documentElement.scrollTop = document.body.scrollTop = 0; //定位滚动条到头部
    document.addEventListener('click', (e) => {
      if (e.path.indexOf(this.$refs.searchWrapper) === -1) {
        this.showSearch(false)
      }
    })
  },
  methods: {
    ...mapMutations("product", ["SET_BRAND_ID", "SET_LABEL", "SET_LEVEL"]),
    showSearch(type) {
      this.isSearch = type
      if (type) {
        setTimeout(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus()
          }
        }, 0);
        
      }
    },
    changeSearch(e) {
      if (this.brandsTabStatus) {
        this.onBrandCategory().then(() => {
          this.brandsList.forEach((el, index) => {
            const res = el.list.filter((brand) => {
              return brand.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
            })
            this.displayBrands[index].list = res
          })
          this.displayBrands = this.displayBrands.filter((el) => {
            return el.list.length
          })
        })
      } else {
        this.onMyBrandCategory().then(() => {
            this.brandsList.forEach((el, index) => {
              const res = el.list.filter((brand) => {
                return brand.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
              })
              this.displayBrands[index].list = res
            })
            this.displayBrands = this.displayBrands.filter((el) => {
              return el.list.length
            })
          }
        );
      }
    },
    onPoint(title) {
      let letterTop = document.getElementById(title).offsetTop;
      if (isPhone) {
        document.documentElement.scrollTop = document.body.scrollTop =
          letterTop;
      } else {
        document.documentElement.scrollTop = document.body.scrollTop =
          letterTop - 73;
      }
    },
    /**
     * 查询品牌列表
     */
    onQueryBrands() {
      this.brandsTabStatus = true;
      this.onBrandCategory().then();
    },
    /**
     * 我的品牌
     */
    onMyBrands() {
      if (!Cookies.get("token")) {
        if (this.isPhone()) {
          $event.$emit(EVENT_NAME.LINKS_LOGIN_REGISTER, "create")
          callBackTop();
        } else {
          $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        }
        return;
      }
      this.brandsTabStatus = false;
      this.onMyBrandCategory().then();
    },
    /**
     * 查询品牌信息
     * @param obj
     */
    onBrandInfo(obj, key) {
      // if(!Cookies.get('token')){
      //     $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS)
      //     return
      // }
      this.offsetTop = Math.ceil((key + 1) / 3 + 1) * 25;
      this.onBrandCategoryConf(obj.id).then();
      for (let i in this.brandsList) {
        for (let j in this.brandsList[i].list) {
          if (obj.id === this.brandsList[i].list[j].id) {
            this.brandsList[i].list[j].visible = true;
          } else {
            this.brandsList[i].list[j].visible = false;
          }
        }
      }
      this.displayBrands = new Array(...this.brandsList)
      this.$forceUpdate();
    },
    /**
     * 选择品牌标签
     * @param title
     */
    onMouseoverBrand(title) {
      this.selectTitle = title;
      this.selectBrands = [];
      for (let i in this.brandsList) {
        this.brandsList[i].visible = false;
        if (this.brandsList[i].letter === title) {
          this.selectBrands.push(this.brandsList[i]);
        }
      }
    },
    /**
     * 选中标签
     * @param id
     */
    onBrandCheck(cid1, brandId) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (this.brandConfSelect.indexOf(cid1) > -1) {
        this.brandConfSelect.splice(this.brandConfSelect.indexOf(cid1), 1);
      } else {
        this.brandConfSelect.push(cid1);
      }
      for (let i in this.brandCategoryConf) {
        if (cid1 === this.brandCategoryConf[i].cid1) {
          this.brandCategoryConf[i].check === 1
            ? (this.brandCategoryConf[i].check = 0)
            : (this.brandCategoryConf[i].check = 1);
        }
      }
      this.onBrandCreateAll(brandId).then();
    },
    /**
     * 全选/取消全选
     */
    onBrandAll(id) {
      if (!Cookies.get("token")) {
        $event.$emit(EVENT_NAME.HEADER_TIPS_STATUS);
        return;
      }
      if (
        this.brandConfSelect.length === 0 ||
        this.brandConfSelect.length !== this.brandCategoryConf.length
      ) {
        this.brandConfSelect = [];
        for (let i in this.brandCategoryConf) {
          this.brandCategoryConf[i].check = 1;
          this.brandConfSelect.push(this.brandCategoryConf[i].cid1);
        }
      } else {
        for (let i in this.brandCategoryConf) {
          this.brandCategoryConf[i].check = 0;
        }
        this.brandConfSelect = [];
      }
      this.onBrandCreateAll(id).then();
    },
    onBrandList(brands) {
      this.SET_BRAND_ID(brands.id);
      this.SET_LABEL(brands.name);
      this.SET_LEVEL("");
      this.$router.push("/brands/list");
    },
    /**
     *  拉取分类id下所有品牌
     * @returns {Promise<void>}
     */
    async onBrandCategory() {
      try {
        this.loadingVisible = true;
        let params = {
          categoryId: this.menuId
        };
        this.selectBrands = [];
        const { code, data } = await getBrandCategoryService(params);
        if (code === 1) {
          this.brandsList = data;
          for (let i in this.brandsList) {
            for (let j in this.brandsList[i].list) {
              this.brandsList[i].list[j].visible = false;
            }
          }
          this.displayBrands = new Array(...this.brandsList)
        }
        // for(let i in this.titleList){
        //     for(let j in data){
        //         if(this.titleList[i] === data[j].letter){
        //              this.selectBrands.push(data[j])
        //         }
        //     }
        // }
        // if(this.letter === ''){
        //     this.onMouseoverBrand('A')
        // }else{
        //     this.onMouseoverBrand(this.letter)
        // }
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     *  拉取分类id下我的品牌
     * @returns {Promise<void>}
     */
    async onMyBrandCategory() {
      try {
        this.loadingVisible = true;
        let params = {
          categoryId: this.menuId
        };
        this.selectBrands = [];
        const { code, data } = await getMyBrandCategoryService(params);
        if (code === 1) {
          this.brandsList = data;
          for (let i in this.brandsList) {
            for (let j in this.brandsList[i].list) {
              this.brandsList[i].list[j].visible = false;
            }
          }
        }
        this.displayBrands = new Array(...this.brandsList)
        this.loadingVisible = false;
      } catch (error) {
        this.loadingVisible = false;
        console.log(error);
      }
    },
    /**
     * 查询商品组品牌列表信息标签
     * @returns {Promise<void>}
     */
    async onBrandCategoryConf(id) {
      try {
        let params = {
          brandId: id,
          categoryId: this.menuType
        };
        const { code, data } = await getBrandCategoryConfService(params);
        if (code === 1) {
          this.brandCategoryConf = data;
          this.brandConfSelect = [];
          for (let i in this.brandCategoryConf) {
            if (this.brandCategoryConf[i].check === 1) {
              this.brandConfSelect.push(this.brandCategoryConf[i].cid1);
            }
            if (this.brandCategoryConf[i].cid1Name === 'Home Fragrance' || this.brandCategoryConf[i].cid1Name === '香氛') {
              const obj = this.brandCategoryConf[i]
              this.brandCategoryConf.splice(i, 1)
              this.brandCategoryConf.splice(4, 0, obj)
            }
            if (this.brandCategoryConf[i].cid1Name === 'Nursery' || this.brandCategoryConf[i].cid1Name === '婴幼儿用品') {
              const obj = this.brandCategoryConf[i]
              this.brandCategoryConf.splice(i, 1)
              this.brandCategoryConf.splice(this.brandCategoryConf.length, 0, obj)
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    /**
     * 查询商品组品牌列表信息标签选中
     * @returns {Promise<void>}
     */
    async onBrandCreateAll(id) {
      try {
        let brandConfSelect = "";
        for (let i in this.brandConfSelect) {
          brandConfSelect += this.brandConfSelect[i] + ",";
        }
        let params = {
          brandId: id,
          categoryIds: brandConfSelect
        };
        const { code } = await getBrandCreateAllService(params);
        if (code === 1) {
          console.log();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
  /**
   * 销毁事件
   */
  destroyed() {
    $event.$off([EVENT_NAME.PRODUCT_BRANDS_QUERY]);
    document.removeEventListener('click', this.showSearch)
  }
};
export default Brands;
